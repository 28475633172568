import React from "react"
import { Link } from "gatsby"
import Msg from "../../form/Msg/index"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-light-svg-icons"

const Table = ({ items }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th className="left">Charity</th>
          <th>Visible</th>
          <th>Modify</th>
        </tr>
      </thead>
      {items.length === 0 ? (
        <tbody>
          <tr>
            <td colSpan="4">
              <Msg data={{ type: "error", text: "No items to display" }} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {items.map(item => {
            return (
              <tr key={item.id}>
                <td className="center">{item.date}</td>
                <td>{item.name}</td>
                <td className="center">{item.visible}</td>
                <td className="center">
                  <Link to={`/charity/${item.id}`}>
                    <FontAwesomeIcon icon={faPencil} />
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      )}
    </table>
  )
}

export default Table
